import './App.css';

import {
  HashRouter as Router, //This means a '#' before every URL, i.e. "/#/projects"
  Routes, 
  Route,
} from 'react-router-dom';

import Header from './components/header/header.component';

import HomePage from './pages/homepage/home-page.component';
import ProjectsPage from './pages/projectspage/projects-page.component';
import BlogPage from './pages/blogpage/blog-page.component';
import ContactPage from './pages/contactpage/contact-page.component';
import GoodreadsPage from './pages/goodreadspage/goodreads-page.component';

function App() {
  return (
    <Router>
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/goodreads" element={<GoodreadsPage />} />
      </Routes>
      <Header />
    </div>
    </Router>
  );
}

export default App;