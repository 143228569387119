import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Stack, Grid, Button, Paper } from '@mui/material';

import { GiBookCover } from 'react-icons/gi'

function ActionAreaCard({projectTitle, projectImageLink, projectURL, 
  projectDescription, projectStackDescription }) {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component="img"
          height="95"
          image={projectImageLink}
          alt={projectTitle}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            <Button variant='contained' color='secondary' href={projectURL}>{projectTitle}</Button>
          </Typography>
          <Typography variant="body2" color="text.primary">
              {projectDescription}
          </Typography>
          <Typography variant="body2" color="text.secondary">
              {projectStackDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
//https://mui.com/components/masonry/#basic-masonry
const ProjectsPage = () => (
    <div>
        <p>A few of my projects, stay tuned for more!</p>
        <Grid container spacing={4} justify="center"> 
          <Grid item xs>
            <Paper>
            <ActionAreaCard
              projectTitle="Simple Quran"
              projectImageLink="/images/projects/simple_quran_image.jpg"
              projectURL="https://simple-quran.netlify.app"
              projectDescription="This is a simple quran viewer,
              more features to come Inshallah!" 
              projectStackDescription="Purely React Static, no State and JSON data"
              />    
            </Paper>
          </Grid>
          <Grid item xs>      
          <Paper>
            <ActionAreaCard /*
              projectTitle="Muslim Library (NEW)" 
              projectImageLink="/images/projects/muslim_library_image_new.png"
              projectURL="https://amazing-visvesvaraya-4b5bae.netlify.app/"
              projectDescription="Obtaining niche books, particularly within the Muslim
              community, is difficult and expensive. Muslim Library was founded to 
              solve this problem using a peer to peer lending mechanism."
              projectStackDescription="React and Tailwind/DaisyUI frontend and Firebase database"
              *//>
          </Paper>
          </Grid>
          <Grid item xs>      
          <Paper>
            <ActionAreaCard
              projectTitle="Muslim Library (OLD)" 
              projectImageLink="/images/projects/muslim_library_image.png"
              projectURL="http://myopenlib.com"
              projectDescription="Obtaining niche books, particularly within the Muslim
              community, is difficult and expensive. Muslim Library was founded to 
              solve this problem using a peer to peer lending mechanism."
              projectStackDescription="React frontend, Firebase database and Typescript
              NodeJS backend with ExpressJS"
              />
          </Paper>
          </Grid>
          <Grid item xs>          
          <Paper>
            <ActionAreaCard
              projectTitle="Harrisdeploy.com" 
              projectImageLink="/images/projects/harris_deploy_image.png"
              projectURL="https://harrisdeploy.com"
              projectDescription="Refactored from my previous pure HTML, CSS and Javascript 
              personal website as an exercise in getting more comfortable with React as well
              as having the aim of showcasing my portfolio of projects."
              projectStackDescription="Refactored from pure HTML, CSS and Javascript to static 
              React deployed with Netlify via Git CI/CD. Styling with MUI library."
              />
            </Paper>
          </Grid>
          <Grid item xs>
          <Paper>           
            <ActionAreaCard /*
              projectTitle="Dashboard.js" 
              projectImageLink="/images/projects/dashboard_image.png"
              projectURL="https://harrisdeploy.com/#/projects/dashboard"
              projectDescription="This is a simplistic dashboard for my life, I typically 
              use it to keep my pomodoros, background noises, and reminders."
              projectStackDescription="Originally in pure Javascript and a HTML page, but 
              recently refactored into React"
              *//> 
            </Paper>
          </Grid>
          <Grid item xs> 
          <Paper>        
            <ActionAreaCard /*
              projectTitle="ArabicQuiz" 
              projectImageLink="/images/projects/arabic_quiz_image.png"
              projectURL="https://harrisdeploy.com/#/projects/arabic_quiz"
              projectDescription="I had a problem with memorising a sheet of Arabic vocab
              sheet sent by my Ustadh (teacher). Developing an ANKI-like memorising tool
              helped me note my progress and automate some of the self quiz process."
              projectStackDescription="React frontend, with Localhost in storing data"
              *//>   
          </Paper>
          </Grid>
        </Grid> 
    </div>
)

export default ProjectsPage;

