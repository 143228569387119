import React from 'react';
import { Container, Grid, Button, Paper, Stack, CardMedia } from '@mui/material';

import { IconButton, ButtonGroup } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
/*

  Introduce myself (Big macro, w/ a nice clear photo, '... 
  [PHOTO] (any generic)
  About ("") 
  "" --> contact page -> form email (need a 'work email'? nah, just stick w/ normal till gotten a work one)

*/
//
const HomePage = () => (
    <div className='homepage'>
        <Container maxWidth='lg' /*sx={{bgcolor: "#cfe8fc"}}*/>
            <Stack spacing={4}>
                <Paper variant='outlined' /*maxWidth='sm'*/>
                    <div className="introduction" >
                        <h1>Hi, I'm Harris!</h1>
                        <div style={{display: "flex", justifyContent: "center"}}> 
                            <CardMedia sx={{maxWidth: 'sm'/*, display: "flex", justifyContent: "center", alignItems: "center"*/}}
                                component='img' 
                                image="/images/homer_nuclear.jpg"
                            />  
                        </div>
                    </div>
                </Paper> 
                <Paper>
                    <div className="about">
                        <h3>I'm interested in building all things digital for businesses.
                        Currently focused on React and Javascript.</h3>
                    </div>
                        <IconButton href="https://www.linkedin.com/in/harris-ahmed-b07813b4/" variant="contained" size="large" color="secondary">
                            <LinkedInIcon />
                        </IconButton>
                        <IconButton href="https://github.com/harrisdeploy" variant="contained" size="large" color="secondary">
                            <GitHubIcon />
                        </IconButton>
                </Paper> 
                <Paper>
                    <div className="to-contact-page-link">
                        <h3>Interested in contacting?</h3>
                        <Button onClick={()=>alert('hello')}>Click me!</Button>                    
                    </div>
                </Paper> 
            </Stack>           
        </Container>
    </div>
);

export default HomePage;