import React from 'react';


const ContactPage = () => (
    <div className='contactpage'>
        <p>This is Contact page </p>
        <div className="to-contact-page-link" />
    </div>
);

export default ContactPage;