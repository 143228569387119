import React from 'react';


const BlogPage = () => (
    <div className='blogpage'>
        <p>This is Blog page </p>
        <a href="/#/goodreads">Goodreads</a>
        <p>
            /*
            https://www.goodreads.com/review/list/61857326?ref=nav_mybooks
            Need to add GR widgets to harrisdeploy.com/#/blog
            How to?
            https://help.goodreads.com/s/article/How-do-I-add-a-widget-to-my-blog-1553870933491
            Thinking of having a basic blog layout with some widgets and links to some 
            posts (i.e. book reviews). Maybe some notes too? Like Arabic vocab or something. 

            
            
            
            */

        </p>
        
    </div>
);

export default BlogPage;

