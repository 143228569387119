import React from 'react';

import { Button, ButtonGroup, Typography, Toolbar, AppBar, Stack } from '@mui/material';
function ButtonAppBar() {
  return (
      <AppBar position="static">
        <Toolbar>
          <Button variant="contained" color="primary" href="#/" size="small">Home</Button>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }} />
          <ButtonGroup variant="contained" color="primary"size="small">
            <Button href="#/projects">Projects</Button>
            <Button href="#/blog">Blog</Button>
            <Button href="#/contact">Contact</Button>
          </ButtonGroup>
        </Toolbar>
      </AppBar>
  );
}

const Header = () => (
  <ButtonAppBar />
)

export default Header; 