/*
Main issue - It loads up all the 








*/
import React from 'react';
// import GoodreadsWidget from './goodreadsWidget.component'; 
import GoodreadsRSS from './goodreadsRSS';

export function AddLibrary(urlOfTheLibrary, className) {
    const script = document.createElement('script');
    script.src = urlOfTheLibrary;
    script.async = true;
    document.body.appendChild(script); //goodreadsListShown
    // document.body.getElementsByClassName(className).appendChild(script); //goodreadsListShown
//    document.body.('goodreadsListShown').appendChild(script); ?

}
            {/*
            https://www.goodreads.com/review/list/61857326?ref=nav_mybooks
            Need to add GR widgets to harrisdeploy.com/#/blog
            How to?
            https://help.goodreads.com/s/article/How-do-I-add-a-widget-to-my-blog-1553870933491
            Thinking of having a basic blog layout with some widgets and links to some 
            posts (i.e. book reviews). Maybe some notes too? Like Arabic vocab or something. 
            */}

const GoodreadsPage = () => (
    <div className='goodreadsPage'>
        <p>This is Goodreads page </p>
        <GoodreadsRSS />
        <a href="/#/blog">Blog</a>
        <div className='goodreadsListShown' />
        <p>End communication</p>
        {/*
            Should be a button, 'Goodreads-dev list' and onClick(), AddLibrary() function, appending to a SPECIFIC 
            div class, and when press button again, should clear previous appending
        */}
        {/*AddLibrary("//rss.bloople.net/?url=https%3A%2F%2Fwww.goodreads.com%2Freview%2Flist_rss%2F61857326%3Fshelf%3Ddev-mech&showtitle=false&type=js",
            "goodreadsListShown")*/}
        
        
        {/*<GoodreadsWidget />*/}
        
    </div>
);

export default GoodreadsPage;

